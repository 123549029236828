import React from 'react';
import "../about/WhatWeBelieveSection.css"


const SundaySchedule = () => {
  return (
    <div className='grid'>
      <div className='block1'>
      <h2>Sunday Schedule</h2>
      <ul>
        <li><strong>Adult Bible Study</strong>: 9:00AM - 9:45AM</li>
        <li><strong>Sunday School</strong> (Children/ College& Career/ Adult): 9:45AM - 10:45AM</li>
        <li><strong>Coffee & Donuts</strong>: 10:45AM - 11:10AM</li>
        <li><strong>Church Service</strong>: 11:15AM - 12:30PM</li>
        <li><strong>Pot Luck Lunch</strong>: 12:30PM - 1:30PM</li>
        <li><strong>Spanish Class</strong>: 1:45PM - 3:30PM</li>
      </ul>
      </div>
      
      <div className='block2'>
      <h2>Weekly Activities</h2>
      <ul>
        <li><strong>Ladies Bible Study</strong>: Tuesday 7:00PM - 8:30PM</li>
        <li><strong>UWM Bible Study</strong>: Tuesday 7:00PM - 8:30PM</li>
        <li><strong>Bible/Prayer</strong>: Wednesday 7:00PM - 8:30PM</li>
        <li><strong>Bible Program</strong>: Friday 7:00PM - 9:00PM</li>
      </ul>
      </div>
    </div>
  );
}

export default SundaySchedule;