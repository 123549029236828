import React from 'react';
import './HomePageText.css';

const HomePageText = (props) => {

  return (
    <div className="row">
      <div className="col-md homepage-text about">
      <h3>Welcome</h3>
        <hr />
        <p>Welcome to East Side Baptist Church. We want to invite you to fellowship with us. GOD’S word defines and describes fellowship in the book of First John. Christian fellowship takes place when one person who knows CHRIST shares CHRIST with another who knows him personally as their Savior. Sometimes we think of fellowship as sharing a meal together or participating in a  common event, but that is not biblical fellowship. One can do these kind of things without achieving the fellowship that GOD describes in HIS word.</p>
        <p>Biblical fellowship is spiritual and is built upon a spiritual, eternal, know-so salvation. The main goal of Christian fellowship is not pleasing ourselves but pleasing the Lord. If you know Christ personally, we invite you to fellowship with us. If you don’t have a relationship with GOD, through HIS mediator JESUS CHRIST, we would like to introduce you to Him. If two believers both have a relationship with CHRIST then they can have fellowship, one with another. Their common faith unites them. Fellowship the way the bible describes it can be enjoyed anywhere, at any time, as long as the focus is on GOD and on GOD’s SON. We would count it a great blessing to have you visit with us during one of your services or bible studies this week. Please come and fellowship with us.</p>
      </div>
      <div className="col-md homepage-text vision">
      <h3>Message From Pastor</h3>
        <hr />
        <p>Our desire is to promote the primitive Christianity of the early church. 2nd Corinthians 10:3 warns us not to be corrupted from the simplicity that is in Christ. God has done all the work, we simply are to receive the work that Christ has already completed by faith. This is true of our salvation and of our sanctification. 
Salvation is by grace alone, through faith alone, in Christ alone. Sanctification is the moment by moment process of being separated from self and from the world unto GOD.
</p>
      <p>Christ’s church is made up of those who have been called out of the world to become GOD’s peculiar possession. We are his and he is ours forever. Our mission is to serve him and please him as he has revealed his will to us in his word. </p>
      </div>
    </div>
  );
}

export default HomePageText;
