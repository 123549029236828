import React from 'react';
import './HomePageIntro.css';

const HomePageIntro = (props) => {

  return (
    <div className="home-page-intro">
        <h2>We Have Merged!</h2>
        <p>On East Sunday of 2023 East Side Baptist Church merged with All Bible Baptist Church. We have retained East Side Baptist as our name and meet at 2409 E Park Place on the east side of Milwaukee. We currently have two websites. Eastsidebapt.org is our old address. Our new website address is eastsidebaptistchurchmilwaukee.org. Eventually, we would be facing out our old website and only use the new one. Pastor E. Peder Methum is now pastor of the newly merged church. Feel free to contact him at epmeth55706@gmail.com and call at
 414 364 3779.
</p>
    </div>
  );
}

export default HomePageIntro;
