import React from 'react';
import HomePageMeetingTimes from '../home/HomePageMeetingTimes';

const WhatsOnSection = (props) => {
  return (
    <div>
      <HomePageMeetingTimes/>
    </div>
  )
}

export default WhatsOnSection;
