import React from 'react';
import './HistorySection.css';


const HistorySection = (props) => {

  return (
    <div className="box">
      <h2 className='text'>Message From Pastor</h2>
      <p>Our desire is to promote the primitive Christianity of the early church. 2nd Corinthians 10:3 warns us not to be corrupted from the simplicity that is in Christ. God has done all the work, we simply are to receive the work that Christ has already completed by faith. This is true of our salvation and of our sanctification. 
Salvation is by grace alone, through faith alone, in Christ alone. Sanctification is the moment by moment process of being separated from self and from the world unto GOD. 
</p>
      <p>Christ’s church is made up of those who have been called out of the world to become GOD’s peculiar possession. We are his and he is ours forever. Our mission is to serve him and please him as he has revealed his will to us in his word. </p>
    </div>
  )
}

export default HistorySection;